import { FormControl, FormItem, FormMessage } from '@/contexts/shared/ui/designSystem/form';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from '@/contexts/shared/ui/designSystem/select';
import { testMakers } from '../../../../../../../../contexts/coche-ya/makers/domain/maker';

type Props = {
  maker?: string;
  makers: typeof testMakers;
  selectMaker: (maker: string) => void;
};
export const MakerSelect = ({ maker, makers, selectMaker }: Props) => {
  return (
    <FormItem className="md:w-full">
      <Select onValueChange={selectMaker} value={maker}>
        <FormControl>
          <SelectTrigger>
            <SelectValue placeholder="Selecciona marca" />
          </SelectTrigger>
        </FormControl>
        <SelectGroup>
          <SelectContent className="max-h-64">
            <SelectLabel> Mas populares</SelectLabel>
            {Object.values(makers.popular).map(maker => (
              <SelectItem key={maker} value={maker}>
                {maker}
              </SelectItem>
            ))}
            <SelectSeparator />
            <SelectLabel>Otros</SelectLabel>
            {Object.values(makers.others).map(maker => (
              <SelectItem key={maker} value={maker}>
                {maker}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectGroup>
      </Select>
      <FormMessage />
    </FormItem>
  );
};
