export type Maker = {
  name: string;
};

export const testMakers = {
  popular: {
    AUDI: 'AUDI',
    BMW: 'BMW',
    CITROEN: 'CITROEN',
    FERRARI: 'FERRARI',
    FIAT: 'FIAT',
    FORD: 'FORD',
    HONDA: 'HONDA',
    LAMBORGHINI: 'LAMBORGHINI',
    SEAT: 'SEAT',
    NISSAN: 'NISSAN',
    OPEL: 'OPEL',
    PORSCHE: 'PORSCHE',
    RENAULT: 'RENAULT',
    TESLA: 'TESLA',
    TOYOTA: 'TOYOTA',
    VOLKSWAGEN: 'VOLKSWAGEN',
    VOLVO: 'VOLVO'
  },
  others: {
    ACURA: 'ACURA',
    AIWAYS: 'AIWAYS'
  }
};
export const cochesNetMakers = {
  popular: {
    ABARTH: 'ABARTH',
    'ALFA ROMEO': 'ALFA ROMEO',
    'ASTON MARTIN': 'ASTON MARTIN',
    AUDI: 'AUDI',
    BENTLEY: 'BENTLEY',
    BMW: 'BMW',
    BUGATTI: 'BUGATTI',
    CADILLAC: 'CADILLAC',
    CHEVROLET: 'CHEVROLET',
    CHRYSLER: 'CHRYSLER',
    CITROEN: 'CITROEN',
    CORVETTE: 'CORVETTE',
    CUPRA: 'CUPRA',
    DACIA: 'DACIA',
    DODGE: 'DODGE',
    'DR AUTOMOBILES': 'DR AUTOMOBILES',
    DS: 'DS',
    EVO: 'EVO',
    FERRARI: 'FERRARI',
    FIAT: 'FIAT',
    FORD: 'FORD',
    HONDA: 'HONDA',
    HUMMER: 'HUMMER',
    HYUNDAI: 'HYUNDAI',
    INFINITI: 'INFINITI',
    JAGUAR: 'JAGUAR',
    JEEP: 'JEEP',
    KIA: 'KIA',
    LADA: 'LADA',
    LAMBORGHINI: 'LAMBORGHINI',
    LANCIA: 'LANCIA',
    'LAND-ROVER': 'LAND-ROVER',
    LEXUS: 'LEXUS',
    LOTUS: 'LOTUS',
    MASERATI: 'MASERATI',
    MAYBACH: 'MAYBACH',
    MAZDA: 'MAZDA',
    MCLAREN: 'MCLAREN',
    'MERCEDES-BENZ': 'MERCEDES-BENZ',
    MG: 'MG',
    MINI: 'MINI',
    MITSUBISHI: 'MITSUBISHI',
    NISSAN: 'NISSAN',
    OPEL: 'OPEL',
    PEUGEOT: 'PEUGEOT',
    POLESTAR: 'POLESTAR',
    PONTIAC: 'PONTIAC',
    PORSCHE: 'PORSCHE',
    RENAULT: 'RENAULT',
    'ROLLS-ROYCE': 'ROLLS-ROYCE',
    ROVER: 'ROVER',
    SAAB: 'SAAB',
    SEAT: 'SEAT',
    SKODA: 'SKODA',
    SMART: 'SMART',
    SSANGYONG: 'SSANGYONG',
    SUBARU: 'SUBARU',
    SUZUKI: 'SUZUKI',
    TESLA: 'TESLA',
    TOYOTA: 'TOYOTA',
    VOLKSWAGEN: 'VOLKSWAGEN',
    VOLVO: 'VOLVO'
  },
  others: {
    ACURA: 'ACURA',
    AIWAYS: 'AIWAYS',
    ALPINE: 'ALPINE',
    ARO: 'ARO',
    ASIA: 'ASIA',
    'ASIA MOTORS': 'ASIA MOTORS',
    AUSTIN: 'AUSTIN',
    AUVERLAND: 'AUVERLAND',
    BERTONE: 'BERTONE',
    BUICK: 'BUICK',
    BYD: 'BYD',
    CATERHAM: 'CATERHAM',
    CENNTRO: 'CENNTRO',
    DAEWOO: 'DAEWOO',
    DAIHATSU: 'DAIHATSU',
    DAIMLER: 'DAIMLER',
    DATSUN: 'DATSUN',
    DFSK: 'DFSK',
    FUSO: 'FUSO',
    GALLOPER: 'GALLOPER',
    GMC: 'GMC',
    HOLDEN: 'HOLDEN',
    INEOS: 'INEOS',
    INNOCENTI: 'INNOCENTI',
    INVICTA: 'INVICTA',
    'INVICTA ELECTRIC': 'INVICTA ELECTRIC',
    ISUZU: 'ISUZU',
    IVECO: 'IVECO',
    KTM: 'KTM',
    LDV: 'LDV',
    LEVC: 'LEVC',
    'LYNK & CO': 'LYNK & CO',
    MAHINDRA: 'MAHINDRA',
    MAXUS: 'MAXUS',
    MORGAN: 'MORGAN',
    NEXTEM: 'NEXTEM',
    PIAGGIO: 'PIAGGIO',
    QOROS: 'QOROS',
    'RENAULT V.I.': 'RENAULT V.I.',
    SAIC: 'SAIC',
    SANTANA: 'SANTANA',
    SWM: 'SWM',
    TALBOT: 'TALBOT',
    TATA: 'TATA',
    TRIDENT: 'TRIDENT',
    UMM: 'UMM',
    VAZ: 'VAZ',
    ZHIDOU: 'ZHIDOU'
  }
};
