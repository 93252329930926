import type { ListingChangeRecords } from '../../../../../../contexts/coche-ya/listings/domain/listing';
import { cocheYaHttpClient } from '../../shared/cocheYaHttpClient';
import { type ListingsResponse, fromResponsesToListings } from '../domain/listings';
import type { ListingsService } from '../domain/ListingsService';

export const getListings: ListingsService['getListings'] = async (
  maker: string,
  model: string,
  version: string
) => {
  const response = await cocheYaHttpClient.get<ListingsResponse>(
    `/listings?maker=${maker}&modelId=${model}&versionId=${version}`
  );

  return {
    listings: fromResponsesToListings(response.listings),
    blockedOn: response.blockedOn
  };
};

export const getMultipleListings = async (makers: string[], models: string[]) => {
  const responses = makers.map((maker, i) =>
    cocheYaHttpClient.get<ListingsResponse>(`/listings?maker=${maker}&modelId=${models[i]}`)
  );
  return (await Promise.all(responses)).map(response => ({
    listings: fromResponsesToListings(response.listings),
    blockedOn: response.blockedOn
  }));
};

export const getMultipleListingsVersions = async (
  makers: string[],
  models: string[],
  versions: string[]
) => {
  const responses = makers.map((maker, i) =>
    cocheYaHttpClient.get<ListingsResponse>(
      `/listings?maker=${maker}&modelId=${models[i]}&versionId=${versions[i]}`
    )
  );
  return (await Promise.all(responses)).map(response => ({
    listings: fromResponsesToListings(response.listings),
    blockedOn: response.blockedOn
  }));
};

export const getListingHistory = async (marketplaceId: string): Promise<ListingChangeRecords> => {
  const response = await cocheYaHttpClient.get<{ listingHistory: ListingChangeRecords }>(
    `/listings/${marketplaceId}/history`
  );
  return response.listingHistory.map(h => ({ fetchedAt: new Date(h.fetchedAt), price: h.price }));
};
