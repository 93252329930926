import type { Marketplaces } from '../../../../../../contexts/coche-ya/listings/domain/listing';
import { Formatter } from '../../../../../../contexts/coche-ya/shared/Formatter';

export type Listing = {
  id: string;
  name: string;
  price: number;
  humanReadablePrice: string;
  kms: number;
  humanReadableKms: string;
  year: number;
  fuel: string;
  img: string;
  url: string;
  marketplace: Marketplaces;
  fetchedAt: Date;
  publishedAt: Date;
  maker: string;
  model: string;
  version: string;
};

export type Listings = Listing[];

const fromResponseToListing = (response: ListingResponse): Listing => ({
  ...response,
  humanReadableKms: Formatter.kms(response.kms),
  humanReadablePrice: Formatter.currency(response.price),
  fetchedAt: new Date(response.fetchedAt),
  publishedAt: new Date(response.publishedAt)
});
export const fromResponsesToListings = (response: ListingResponse[]): Listings =>
  response.map(fromResponseToListing);

export function groupListingsByYear(listings: Listing[]): Listing[][] {
  const map = new Map();
  for (const listing of listings) {
    const year = listing.year;
    if (map.has(year)) {
      map.get(year).push(listing);
    } else {
      map.set(year, [listing]);
    }
  }
  return Array.from(map.values());
}

type GroupListingsByKmsResult = {
  groupedListings: Listings[];
  kmsRangePerGroup: number;
};

export function groupListingsByKms(listings: Listing[]): GroupListingsByKmsResult {
  if (!listings.length) return { groupedListings: [], kmsRangePerGroup: 0 };
  const map = new Map<number, Listing[]>();
  const listingsByKms = [...listings].sort(orderListingByKms);
  const kmsDiff = listingsByKms[listingsByKms.length - 1].kms - listingsByKms[0].kms;
  const numberOfFolds =
    listingsByKms.length <= 2
      ? 1
      : listings.length <= 4
        ? 2
        : listings.length <= 6
          ? 3
          : kmsDiff <= 100000 // more than 6 cars with a difference or less than 100k, 4 folds
            ? 4
            : kmsDiff <= 150000 // more than 6 cars with a difference or less than 150k, 6 folds
              ? 6
              : 8;
  const kmsTick = kmsDiff / numberOfFolds;

  for (const listing of listingsByKms) {
    const listingGroup = Math.floor((listing.kms - 1 - listingsByKms[0].kms) / kmsTick); // from 0 to 3
    if (map.has(listingGroup)) {
      map.get(listingGroup)?.push(listing);
    } else {
      map.set(listingGroup, [listing]);
    }
  }
  return { groupedListings: Array.from(map.values()), kmsRangePerGroup: Math.floor(kmsTick) };
}

type WithYear = { year: number };
export const orderListingByYear = (a: WithYear, b: WithYear) => a.year - b.year;
type WithKms = { kms: number };
export const orderListingByKms = (a: WithKms, b: WithKms) => a.kms - b.kms;

export type ListingsResponse = {
  listings: ListingResponse[];
  blockedOn: {
    [keyof in Marketplaces]: boolean;
  };
};

export type ListingResponse = {
  id: string;
  name: string;
  maker: string;
  model: string;
  version: string;
  price: number;
  kms: number;
  year: number;
  fuel: string;
  img: string;
  url: string;
  marketplace: Marketplaces;
  fetchedAt: string | Date;
  publishedAt: string | Date;
};
