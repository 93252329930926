import { FormControl, FormItem, FormMessage } from '@/contexts/shared/ui/designSystem/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/contexts/shared/ui/designSystem/select';
import type { Models } from '../../../../../modules/models/domain/model';

type Props = {
  model?: string;
  models?: Models;
  selectModel: (maker: string) => void;
};
export const ModelSelect = ({ model, models, selectModel }: Props) => {
  return (
    <FormItem className="md:w-full">
      <Select onValueChange={selectModel} value={model}>
        <FormControl>
          <SelectTrigger>
            <SelectValue placeholder="Seleciona modelo" />
          </SelectTrigger>
        </FormControl>
        <SelectContent className="max-h-64">
          {models &&
            models.map(model => (
              <SelectItem key={model.id} value={model.name}>
                {model.name}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  );
};
