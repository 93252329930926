import { cocheYaHttpClient } from '../../shared/cocheYaHttpClient';
import type { Models } from '../domain/model';
import type { ModelsService } from '../domain/modelService';

export const getModels: ModelsService['getModels'] = async (maker: string) => {
  const response: { models: Models } = await cocheYaHttpClient.get(`/models?maker=${maker}`);
  return response.models;
};

export const getMultipleModels: ModelsService['getMultipleModels'] = async (makers: string[]) => {
  const response = makers.map(maker =>
    cocheYaHttpClient.get<{ models: Models }>(`/models?maker=${maker}`)
  );
  const result = await Promise.all(response);
  return result.map(response => response.models);
};
