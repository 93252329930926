import { cocheYaHttpClient } from '../../shared/cocheYaHttpClient';
import { Versions } from '../domain/version';
import { VersionService } from '../domain/VersionService';

export const getVersion: VersionService['getVersion'] = async (model: string) => {
  const response = await cocheYaHttpClient.get<{ versions: Versions }>(`/versions?model=${model}`);
  return response.versions;
};
export const getMultipleVersions: VersionService['getMultipleVersions'] = async (
  models: string[]
) => {
  const response = models.map(model =>
    cocheYaHttpClient.get<{ versions: Versions }>(`/versions?model=${model}`)
  );
  const result = await Promise.all(response);
  return result.map(response => response.versions);
};
