import { FormControl, FormItem, FormMessage } from '@/contexts/shared/ui/designSystem/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/contexts/shared/ui/designSystem/select';
import type { Versions } from '../../../../../modules/versions/domain/version';

type Props = {
  version?: string
  versions?: Versions
  selectVersion: (maker: string) => void
}
export const VersionSelect = ({ version, versions, selectVersion }: Props) => {
  return (
    <FormItem className='md:w-full'>
      <Select
        disabled={!versions}
        onValueChange={selectVersion}
        value={version ? version : ''}
      >
        <FormControl>
          <SelectTrigger>
            <SelectValue placeholder='Selecciona versión' />
          </SelectTrigger>
        </FormControl>
        <SelectContent className='max-h-64'>
          <SelectItem value='all'>Todas las versiones</SelectItem>
          {versions?.map(version => (
            <SelectItem key={version.id} value={version.name}>
              {version.name} ({version.totalListings})
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  )
};